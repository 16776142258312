.container.breadcrumb {
  padding: 0;
  margin-top: 35px;
  margin-bottom: 65px;
  ul.breadcrumb {
    margin-bottom: 0;
    li {
      a {
        color: @black;
        text-decoration: underline;
      }
      &.active {
        a {
          color: @red;
          text-decoration: none;
        }
      }
    }
  }
}