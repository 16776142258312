// Colors

@red: #e40038;
@gray: #5e767b;
@grayBg: #eee;
@white: #fff;
@black: #000;

// Link
@link-color: @red;

// Navi
@nav-link-padding: @grid-gutter-width*2 35px;
@navbar-height: 0;
@navbar-border-radius: 0;
@navbar-margin-bottom: 0;
@navbar-padding-horizontal: 0;
@navbar-default-bg: @red;
@navbar-default-border: @red;
@navbar-default-link-color: @white;

@navbar-default-link-hover-color: @red;
@navbar-default-link-hover-bg: @white;

@navbar-default-link-active-color: @white;
@navbar-default-link-active-bg: @red;

@zindex-dropdown: 500;
@dropdown-bg: @white;
@dropdown-border: @white;
@dropdown-divider-bg: @white;
@dropdown-link-color: @red;
@dropdown-link-hover-color: @white;
@dropdown-link-hover-bg: @red;
@dropdown-link-active-color: @white;
@dropdown-link-active-bg: @red;

// Navbar toggle
@navbar-default-toggle-hover-bg:           @white;
@navbar-default-toggle-icon-bar-bg:        @white;
@navbar-default-toggle-border-color:       @white;
// Überschreiben des navbar toogles ist nutzlos, da dieser in den bootstrap less files nicht genutzt wird !!! bug !!!

// Breadcrumb
@breadcrumb-padding-vertical:   0;
@breadcrumb-padding-horizontal: 0;
@breadcrumb-bg:                 @white;
@breadcrumb-color:              @gray;
@breadcrumb-active-color:       @red;
@breadcrumb-separator:          " >";

// deactivate border radius
@border-radius-base: 0;

.box_shadow() {
  -webkit-box-shadow: 0 2px 1px 0 rgba(0,0,0, 0.3);
  -moz-box-shadow: 0 2px 1px 0 rgba(0,0,0, 0.3);
  box-shadow: 0 2px 1px 0 rgba(0,0,0, 0.3);
}

// Fonts
@fontRegular: 'bariol_regularregular';
@fontBold: 'bariol_boldbold';
.bariol_regular() {
  font-family: @fontRegular, sans-serif;
}
.bariol_bold() {
  font-family: @fontBold, sans-serif;
}
/*
  Achgunt: Die beiden Fonts werden über separate CSS-Files eingebungen. Scheint nicht anders zu funktionieren...

  siehe ../fonts/bariol_regular/bold.css
 */

@fontSize: 18px;
@fontSizeH1: 40px;
@fontSizeH2: 40px;
@fontSizeH3: 18px;
@fontSizeL1: 20px;
@fontSizeL2: 18px;

@fontSizeMobile: 18px;
@fontSizeH1mobile: 30px;
@fontSizeH2mobile: 30px;
@fontSizeH3mobile: 18px;
@fontSizeL1mobile: 20px;
@fontSizeL2mobile: 18px;

.h1() {
  .bariol_regular();
  //font-family: 'Open-Sans', "Wingdings 3";
  font-size: @fontSizeH1;
  @media(@bis-xs) {
    font-size: @fontSizeH1mobile;
  }
  color: @red;
}
.h2() {
  .bariol_regular();
  font-size: @fontSizeH2;
  margin-bottom: 30px;
  @media(@bis-xs) {
    font-size: @fontSizeH2mobile;
  }
  color: @red;
}
.h3() {
  .bariol_regular();
  font-size: @fontSizeH3;
  @media(@bis-xs) {
    font-size: @fontSizeH3mobile;
  }
  color: @red;
}


.p() {
  .bariol_regular();
  font-size: @fontSize;
  @media(@bis-xs) {
    font-size: @fontSizeMobile;
  }
  color: @gray;
  line-height: 1.25em;
}


.triangleBorderTopRight(@width, @color) {
  margin-right: @width;
  z-index: 999;
  &::after {
    z-index: 111;
    content: " ";
    width: @width;
    height: 100%;
    border-style: solid;
    border-width: @width 0 0 @width;
    border-color: transparent transparent transparent @color;
    right: -@width;
    top: 0;
    position: absolute;
  }
}

.triangleBorderBottomRight() {
  /*position: relative;
  z-index: 0;
  overflow: visible;
  &::after {
    opacity: 0;
    overflow: visible;
    z-index: 1;
    position: absolute;
    right: -1px;
    bottom: -3px;
    content: " ";
    width: 20px;
    height: 20px;
    background: url(/fileadmin/templates/desconpro/images/white-corner.png) no-repeat right bottom;
  }*/
  position: absolute;
  z-index: 0;
  overflow: visible;
  width: ~"calc(100% - 21px)";
  height: 24px;
  background-image: url(/fileadmin/templates/desconpro/images/shadow.png);
  background-repeat: repeat-x;
  &:after {
    overflow: visible;
    z-index: 1;
    position: absolute;
    top: 0;
    right: -21px;
    content: " ";
    width: 21px;
    height: 24px;
    background: url(/fileadmin/templates/desconpro/images/corner.png) no-repeat right bottom;
  }
}