.mp-content-header {
  margin-bottom: 25px;
  h2 {
    color: @gray;
  }
}

.frame {
  header {
    h2 {
      .h1();
    }
  }
}
