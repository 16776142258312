// Überschrift soll keinen Abstand nach oben haben

h1, .h1,
h2, .h2,
h3, .h3 {
  /*margin-top: @line-height-computed;*/
  margin-top: 0;
  margin-bottom: (@line-height-computed / 2);
}
h4, .h4,
h5, .h5,
h6, .h6 {
  /*margin-top: (@line-height-computed / 2);*/
  margin-top: 0;
  margin-bottom: (@line-height-computed / 2);
}