.mpContentContentContainer {
  div.downloads {
	margin: floor((@grid-gutter-width / 2)) 0;
  }
  ul.icon-list li {
	padding: 0 0 5px 20px;
	display: block;
	position: relative;
  }
  ul.icon-list li:before {
	font-family: 'Glyphicons Halflings';
	position: absolute;
	left: 0px;
	top:3px;
  }
  ul.download li:before {
	content: "\e175";
  }
  .contenttable td {
	vertical-align: top;
  }
}


.img-pull-left {
  float: left !important;
  width: 50%;
  margin-right: @grid-gutter-width/2;
}
.img-pull-right {
  float: right !important;
  width: 50%;
  margin-left: @grid-gutter-width/2;
}