.mp_dynamicheader {
  .dynamicHeader {
    .slide {

      @media(@bis-xs) {
        .container {
          padding: 0;
        }
      }

      h2,h3 {
        .h1();
      }
      h2 {
        background-color: @red;
        color: @white;
        padding: 15px 40px;
        margin: 0;
        height: 96px;
      }
      @media(@ab-sm) {
        h2 {
          display: none;
        }
      }

      .caption {
        background-color: @gray;
        position: relative;
        height: 140px;
        padding: 0 40px;

        @media(@ab-sm) {
          margin-top: -70px;
        }
        @media(@bis-xs) {
          min-height: 120px;
          // margin-top: 90px;
        }

        h3 {
          @media(@bis-xs) {
            /*display: none;*/
          }
          .h1();
          color: @white;
          background-color: @red;
          width: 510px;
          height: 160px;
          padding: 45px 30px 80px 30px;
          position: absolute;
          margin-top: -20px;
          margin-bottom: 0;
          .triangleBorderTopRight(20px, @red);
        }
        p {
          padding-left: 560px;
          padding-right: 10px;
          color: @white;
          padding-top: 30px;
          font-size: 22px;
          line-height: 1.3em;
        }

        @media(@bis-md) {
          h3 {
            width: 45%;
          }
          p {
            float: right;
            padding-left: 0;
            max-width: 45%;
          }
        }
        @media(@bis-sm) {
          h3 {
            //width: 40%;
            font-size: @fontSizeH1 - 5;
          }
          p {
            max-width: 40%;
            font-size: @fontSizeL1 - 2;
          }
        }
        @media(@bis-xs) {
          padding: 0 35px;
          h3{
            width: ~"calc(100% - 30px)";
            height: 70px;
            top: 0;
            padding: 15px 20px 20px 40px;
            font-size: 22px;
            margin-left: -40px;
          }
          /*
          h3 {
            top: -70px;
            position: relative;
            margin-left: -10px;
            width: 200%;
            padding-top: 20px;
            padding-bottom: 10px;
            margin-left: -40px;
            height: 70px;
            font-size: 20px;
            overflow: hidden;
            z-index: 0;
          }
          */

          p {
            // margin-top: -50px;
            max-width: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            padding-top: 64px;
            height: 100%;
            font-size: 16px;
            span {
              position: relative;
              margin: auto;
              display: inline-block;
            }
            // margin-bottom: 0;
          }
        }
        @media(@bis-xxs) {
          /*
          h3 {
            padding-top: 10px;
            top: -60px;
          }
          */
          p {
            font-size: 14px;
            // margin-top: -60px;
            
            // padding-top: 10px;
          }
        }

        .cycle-prev,
        .cycle-next {
          width: 40px;
          height: 140px;
          position: absolute;
          background-repeat: no-repeat;
          background-position: center;
          top: 0;
          cursor: pointer;
          max-height: 100%;
          @media(@bis-xs){
            background-position: center 82px;
          }
        }
        .cycle-prev {
          left: 0;
          background-image: url(/fileadmin/templates/desconpro/images/icons/arrow-white-left.png);
        }
        .cycle-next {
          right: 0;
          background-image: url(/fileadmin/templates/desconpro/images/icons/arrow-white-right.png);
        }
      }
    }
  }

}