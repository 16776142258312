#footer {
  background-color: @gray;
  color: @white;

  padding-top: 100px;
  padding-bottom: 120px;

  ul {
    list-style: none;
    padding-left: 0;
    li {
      font-size: @fontSize - 2;
      &.active {
        font-weight: bold;
      }
      a {
        color: @white;
      }
    }
  }

  .logo-wrap {
    img {
      max-height: 50px;
      max-width: 200px;
      float: right;
    }
  }

  @media(@bis-xs) {
    padding-top: 50px;
    padding-bottom: 80px;

    .logo-wrap {
      padding-top: 80px;
    }
  }
}