// Ab hier kommen die Styles, welche für die HTML Templates nötig sind (Alles was keine Ext ist)
//Header Logo
.logo-wrap {
  padding: @grid-gutter-width/4 0;
}
//Header Metanav
.navbar-right.metanavigation {
  margin-right: 0;
}

//Header Mainnav
#mpDynamicHeader .dynamicHeader .slide {
  width: 100%;
}

/* WICHTIG FÜR BOOTSTRAP DROPDOWN MENU => OPEN ON HOVER*/
/*.open > .dropdown-menu li:hover .dropdown-menu {
  display: block;
}*/
.mainMenu ul.nav li.dropdown:hover > ul.dropdown-menu {
  display: block;
}

.navbar {
  // margin-top: @grid-gutter-width/2;
  // margin-bottom: @grid-gutter-width/2;
}

// .main { margin-top: 100px; }






@import "variables";


@import "header";
@import "body";
@import "footer";