nav {
  border: none;
  float: right;

  li {
    a {
      .bariol_regular();
      font-size: @fontSizeL2;
    }
    .dropdown-menu {
      border: none;
      // padding-top: @grid-gutter-width;
      min-width: 200px;

      li {
        a {
          font-size: @fontSizeL2;
          line-height: 1;
          border-top: 1px solid @white;
        }
        /*&.last {
          height: 20px;
          .triangleBorderBottomRight();
        }*/
        &.last{
          .triangleBorderBottomRight();
        }
      }
    }
  }

  .nav > li {
    & > a {
      padding-top: 15px;
      padding-bottom: 25px;
    }
    .caret {
      position: absolute;
      top: auto;
      bottom: 10px;
      left: 0;
      right: 0;
      width: 15px;
      height: 7px;
      margin: 0 auto;
      padding: 0;
      // background-image: url(/fileadmin/templates/desconpro/images/icons/arrow-white.png);
      border: none;
    }
    &:hover,
      &:focus{
      .caret {
        background-image: url(/fileadmin/templates/desconpro/images/icons/arrow-red.png);
      }
    }
    &.active {
      .caret {
        background-image: url(/fileadmin/templates/desconpro/images/icons/arrow-white.png);
      }
    }
  }

  li a {
    @media(@nur-md) {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
    @media(@nur-sm) {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  .dropdown-menu {
    /*display: block;*/
    // margin-top: -100%;
    // z-index: 333;
   /* opacity: 0;*/
    padding: 0;
    margin: 0;
    min-width: 100%;

    .box_shadow();

    li {
      a {
        padding: @grid-gutter-width/2 35px;
      }
    }

    /*
    -webkit-transition: margin-top 1s;
    -moz-transition: margin-top 1s;
    -ms-transition: margin-top 1s;
    -o-transition: margin-top 1s;
    transition: margin-top 1s;
    */


    -webkit-transition: opacity .333s;
    -moz-transition: opacity .333s;
    -ms-transition: opacity .333s;
    -o-transition: opacity .333s;
    transition: opacity .333s;
  }

  .dropdown:hover > ul {
    // margin-top: 0;
   /* opacity: 1;*/
  }

  @media(@bis-xs) {
    position: absolute;
    top: 0;
    //padding-top: @grid-gutter-width;
    left: 0;
    right: 0;
    width: 100%;
    // text-align: center;
    //background-color: @white;
    //color: @black;
    .navbar-collapse {
      border: none;
      .nav {
        padding: 10px 0;
        margin: 0;
      }
    }
    .navbar-toggle {
      border: none;
      position: fixed;
      top: 0;
      right: 0;
      margin: 0;
      padding: 23px;
      &:focus,
      &:hover,
        &:focus{
        background: none;
      }
    }
    .caret {
      display: none;
    }
    .navbar-nav{
      border-top: 1px solid @white;
    }
    li {
      a {
        padding: @grid-gutter-width/2 !important;
        //color: @black;
        float: left;
        width: 100%;
      }
      &:hover,
      &:focus,
      &.active {
        a {
          // background-color: @grayBg;
          //color: @red;
        }
      }
      &.last {
        display: none;
      }
    }

    .dropdown {
      a {
        //color: @black;
        //background-color: @white;
        background-color: @red;
        color: @white;
      }
      &:hover,
      &:focus,
      &.active {

        .dropdown-menu {
          display: block;
          width: 100%;
          li {
            a {
              //background-color: @gray;
              //color: @white;
              // text-align: center;
              //border-top: none;
              border-bottom: 1px solid @white;
            }
          }
        }
      }
      .dropdown-menu {
        position: relative;
        display: none;
        //background-color: @gray;
        opacity: 1;
        box-shadow: none;
        background-color: @white;
        color: @red;
        li {
          //padding: 0 1em;
          a {
            //color: @black;
            padding-left: @grid-gutter-width !important;
            padding-right: @grid-gutter-width !important;
            background-color: @white;
            color: @red;
          }
          &.active,
          &:hover,
            &:focus{
            a {
              //text-decoration: underline;
              //color: @red;
              background-color: @grayBg;
              color: @red;
            }
          }
        }

      }
    }
  }
}