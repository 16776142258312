#header {
  .top {
    background-color: @red;
    height: 110px;

    // fixed navi
    position: fixed;
    width: 100%;
    max-width: 1440px;
    top: 0;
    z-index: 1234;
    // fixed navi - end

    .row {
      position: relative;
      height: 110px;

      .logoColumn {
        @media(@ab-sm) {
          position: absolute;
          bottom: 0;
        }
        .logo-wrap {
          padding: 35px 0 25px 0;
          img {
            max-height: 50px;
            max-width: 235px;
          }
        }
      }
      .menuColumn {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  @import "header.navi.less";

  @media(@bis-xs) {
    .top {
      height: 60px;
      .row {
        height: 60px;
        .logoColumn {
          .logo-wrap {
            padding: 0;
            margin-right: 60px;
            img {
              // max-height: 44px;
              // padding: 2px 0;
              // max-width: 175px;
              width: 223px;
              height: 60px;
              max-width: 223px;
              max-height: 100%;
            }
          }
        }

        .menuColumn {
          position: relative;
        }
      }
    }
  }
}