body {
  // fixed navi
  padding-top: 110px;
  overflow-x: hidden;
  @media(@bis-xs) {
    padding-top: 60px;
  }
  // fixed navi - end

  max-width: 1440px;
  margin: 0 auto;

  .h1, h1 {
    .h1();
  }
  .h2, h2 {
    .h2();
  }
  .h3, h3 {
    .h3();
  }
  &,p {
    .p();
  }

  a:hover {
    text-decoration: none;
  }

  -ms-word-break: break-word;
  word-break: break-word;

  @import "breadcrumb";

  main {

    .main {
      .frame-type-menu_sitemap {
        ul {
          padding-left: 0;
          li {
            margin-left: 1.25em;
          }
        }
      }
      & > .white {
        margin-top: 60px;
        margin-bottom: 60px;
        @media(@bis-xs){
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
      & > .gray,
      & > .red {
        padding: 60px 0;
        @media(@bis-xs){
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }
      & > .white {
        // border-right: 1px solid @gray;
      }
      & > .gray {
        background-color: @grayBg;
      }
      & > .red {
        background-color: @red;
        a,p,h2,h3,h4 {
          color: @white;
        }
      }
    }
  }
}