.tx-mp-news {

  h1 {
    margin-bottom: 1.5em;
  }

  .newsListContainer {
    .newsListItem,
    .newsTeaserItem {
      position: relative;
      margin-bottom: 1em;
      .box_shadow();
      &:after{
        display: block;
        content: '';
        position: absolute;
        width: ~"calc(100% + 2px)";
        height: 24px;
        background-color: #fff;
        right: -2px;
        z-index: 0;
        bottom: -5px;
      }
      h2 {
        padding: 20px 30px 22px 30px;
        margin: 0;
        font-size: 18px;
      }
     .borderBottom{
       .triangleBorderBottomRight();
       bottom: -4px;
       z-index: 1;
     }
    }
    ul.f3-widget-paginator {
      float: left;
      padding-left: 0;
      padding-top: @grid-gutter-width;
      margin: 0 @grid-gutter-width/2;
      list-style: none;
      width: 100%;
      li {
        float: left;
        padding: 0.25em 1em;
        &.current {
          color: @white;
          background-color: @red;
        }
        &.next,
        &.previous {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

  .newsListItemContent{
    padding-bottom: 30px;
    .listHeaderLink{
      h2{
        font-size: 18px !important;
      }
    }
    .newsItemDate,
    .newsListItemMoreLink,
    .newsListItemTeaserText{
      padding: 0 30px;
    }
    .listTeaserTextLink{
      font-size: 16px;
      color: @black;
      &:hover,&:focus{
        text-decoration: none;
      }
    }
    .newsItemDate{
      margin-top: 20px;
      font-size: 14px;
      color: @gray;
    }
  }

  }

  .newsSingleItem {
    .newsSingleItemLeftContent {
      p:first-child {
        margin-bottom: 1em;
      }
    }
  }



}