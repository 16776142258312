.mp-content-carousel-gallery {
  .mpCarouselGallery {
	.jcarousel-wrapper {
	  .jcarousel-control-prev, .jcarousel-control-next {
		color: transparent;
		position: absolute;
		top: 50%;
		margin-top: -15px;
		width: 20px;
		height: 28px;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		font: 24px/27px Arial, sans-serif;
	  }
	  .jcarousel-control-prev:hover, .jcarousel-control-next:hover {
		background-position: 0px -28px;
	  }
	  .jcarousel-control-prev {
		background: transparent url(../../../typo3conf/ext/mp_content_gallery_jcarousel/Resources/Public/Icons/left.png) no-repeat 0 0;
	  }
	  .jcarousel-control-next {
		background: transparent url(../../../typo3conf/ext/mp_content_gallery_jcarousel/Resources/Public/Icons/right.png) no-repeat 0 0;
	  }
	}
  }
}