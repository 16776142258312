.mp-content-carousel-gallery {
  .mpCarouselGallery {
    .jcarousel-wrapper {
      margin: 0;
      padding: @grid-gutter-width 0;
      background-color: @grayBg;

      .jcarousel {
        margin: 0;
        li {
          margin-right: 20px;
          margin-left: 0;
        }
      }
      .jcarousel-control-prev,
      .jcarousel-control-next {
        display: inline-block;
        width: 49px;
        height: 139px;
        top: 25px;
        &:hover {
          background-position: center;
        }
        &.inactive {
          opacity: 0.25;
        }
        background-position: center;
        background-repeat: no-repeat;

        @media(@bis-sm) {
          max-height: 100%;
        }
        @media(@bis-xs) {
          max-height: 75%;
          top: 120%;
        }
      }
      .jcarousel-control-prev {
        background-image: url(/fileadmin/templates/desconpro/images/icons/arrow-black-left.png);
      }
      .jcarousel-control-next {
        background-image: url(/fileadmin/templates/desconpro/images/icons/arrow-black-right.png);
      }
    }
  }
}