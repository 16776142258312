.mp_dynamicheader {
  .dynamicHeader {
	.slide {

	  overflow:hidden;
	  width:1550px;
	  max-width: 100%;
	  height: auto;
	  position: relative;

	  .caption {

		padding: @grid-gutter-width/2;
		width: 100%;
		position: absolute;
		bottom: 0;
		background:rgba(255,255,255,0.75);

		.dynamicHeadline1, .dynamicHeadline2 { display: block; }
		.dynamicHeadline1 {  }
		.dynamicHeadline2 {  }
	  }
	}
  }

  .cycle-prev, .cycle-next {
	display: block;
	position: absolute;
	top: 49%;
	z-index: 199;
	font-size: 300%;
  }
  .cycle-prev {
	left: @grid-gutter-width/2;
  }
  .cycle-next {
	right: @grid-gutter-width/2;
  }

  .mp_dynamicheader .caption {
    position: absolute;
    bottom: 0;
    background:rgba(255,255,255,0.75);
  }

  .mp_dynamicheader .slide {
    overflow:hidden;
    width: 100%;
    /*height:400px;*/
    height: auto;
    position: relative;
  }

}
