.zwei-spalten-getrennt-50-50{
  /*margin-bottom: 60px !important;*/
  @media(@bis-xs){
   /* margin-bottom: 30px !important;*/
  }
}
.zwei-spalten-getrennt-66-33{
  margin-bottom: 60px !important;
  @media(@bis-xs){
    margin-bottom: 30px !important;
  }
  .second{
    background: @grayBg;
    padding: 30px;
    p,li{
      font-family: @fontBold;
    }
    &:after{
      content: " ";
      width: 100%;
      height: 20px;
      border-style: solid;
      border-width: 20px 20px 0 0;
      border-color: @grayBg transparent transparent transparent;
      position: absolute;
      bottom: -20px;
      left: 0;
    }
    @media(@bis-xs){
      margin-left: @grid-gutter-width/2;
      margin-right: @grid-gutter-width/2;
    }
  }
}