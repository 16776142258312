article {
  &.mp {
    // border-left: 1px dotted @red;
  }
  &.content {
    // border-right: 1px dashed @red;

    .bodyText {
      ul {
        padding-left: 0;
        li {
          margin-left: 1em;
        }
      }
      a.download-pdf {
        background: url(/fileadmin/templates/desconpro/images/download_pdf.png);
        background-repeat: no-repeat;
        display: inline-block;
        min-height: 20px;
        padding-left: 20px;
        line-height: 20px;
        &:before {
          content: ""
        }
      }
      h2,h3,h4,h5,h6 {
        margin-bottom: 0;
        line-height: 1.25em;
      }
      p {

        strong {
          // color: @red;
        }
      }
    }
  }

  &.ce,
  &.tb {
    h2 {
      // .h3();
      //color: @red;
    }
    h3 {
      //color: @red;
    }
  }
}


ul{
  padding-left: 20px;
}

.mp-content-ce-text{
 /* margin-bottom: 60px;*/
}

.mp-content-ce-twil,
.mp-content-ce-twi´r{
  img{
    margin-bottom: 20px;
  }
}

.button{
  a{
    padding: 10px 15px;
    background: @red;
    color: @white;
    text-decoration: none;
    &:hover,&:focus{
      text-decoration: none;
      background: @gray;
    }
  }
}