.tx-mp-news {
  .newsListContainer {
	> .listNewsItem {
	  margin-top: @grid-gutter-width/2;
	  margin-bottom: @grid-gutter-width/2;
      padding-bottom: @grid-gutter-width;
      border-bottom: 1px solid #ccc;
	}
	.newsListItem {
	  .newsItemDate {

	  }
	  .newsListItemContent {
		h2 { margin: 5px 0; font-size: 180%; }
		h3 { margin: 5px 0; font-size: 150%; }
		.listHeaderLink { h2 { margin: 5px 0; font-size: 180%; } }
		.listSubheaderLink { h3 { margin: 5px 0; font-size: 150%; } }

		.newsListItemTeaserText {
		  a.listTeaserTextLink {

		  }
		}
		.newsListItemMoreLink {
		  a.listMoreLink {
			margin-top: .5em;
			display: inline-block;
		  }
		}
	  }
	}
  }
  .newsSingleItem {
	.newsSingleItemContent {
	  margin-top: @grid-gutter-width/2;
	  margin-bottom: @grid-gutter-width/2;
	  display: inline-block;
	}
	.newsSingleItemBackToListLink {
	  .singleBackLink {
		margin-top: @grid-gutter-width/2;
		margin-bottom: @grid-gutter-width/2;
		display: inline-block;
	  }
	}
  }
}